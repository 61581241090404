/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import ogImage from 'src/images/og-image.png'
import { useStaticQuery, graphql } from 'gatsby'

type SEO = {
  title?: string;
  description?: string;
  keywords?: string;
  shareImage?: string;
  url?: string;
  type?: 'website' | 'article';
}

type SiteMetadata = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
      author: string;
      siteUrl: string;
      type: 'website' | 'article';
    };
  };
}

function SEO ({ title, description, keywords, shareImage, url, type }: SEO) {
  const { site }: SiteMetadata = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
            type
          }
        }
      }
    `,
  )

  // Reference: https://www.joaopedro.cc/blog-com-gatsby-e-react-parte-4#melhorando-o-seo
  const seo = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    keywords: keywords || site.siteMetadata.keywords,
    image: shareImage ? `${site.siteMetadata.siteUrl}${shareImage}` : `${site.siteMetadata.siteUrl}${ogImage}`,
    url: url ? `${site.siteMetadata.siteUrl}${url}` : site.siteMetadata.siteUrl,
    type: type || site.siteMetadata.type,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'pt-BR',
      }}
      title={seo.title}
    >
      <link rel='canonical' href={seo.url} />
      <meta name='description' content={seo.description} />
      <meta name='keywords' content={seo.keywords} />
      <meta name='image' content={seo.image} />
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      <meta property='og:url' content={seo.url} />
      <meta property='og:image' content={seo.image} />
      <meta property='og:type' content={seo.type} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:image' content={seo.image} />
      <meta name='twitter:site' content='@Thiagosalome' />
    </Helmet>
  )
}

export default SEO

// https://getbootstrap.com/docs/4.3/utilities/spacing/#how-it-works
const spaces = {
  xxs: '.25rem',
  xs: '.5rem',
  sm: '.75rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  xxl: '3rem',
} as const

export default spaces

import React, { useState, MouseEvent } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { FiMenu } from 'react-icons/fi'
import { useLocation, useNavigate } from '@reach/router'

// Components
import SocialNetwork from 'src/components/SocialNetwork'

// Helpers
import scrollTo from 'src/helpers/scrollTo'
import { trackClick } from 'src/helpers/ga'

// Styles
import { MenuContainer } from './style'

function Menu () {
  const [ open, setOpen ] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  function handleClick () {
    setOpen(!open)
  }

  function scroll (evt: MouseEvent<HTMLAnchorElement>) {
    const anchor = evt.currentTarget as HTMLAnchorElement
    trackClick('Menu', 'Clique em item do menu', anchor.title)
    if (location.pathname === '/') {
      scrollTo(evt)
      setOpen(!open)
    } else {
      navigate(`/#${anchor.id}`)
    }
  }

  return (
    <MenuContainer>
      <div className='burger' onClick={handleClick}><FiMenu /></div>
      <div className={`content ${open ? 'active' : ''}`}>
        <span className='back' onClick={handleClick}><IoMdArrowBack /></span>
        <ul className='list'>
          <li><a href='#about' title='Sobre mim' onClick={scroll}>Sobre mim</a></li>
          <li><a href='#skills' title='Habilidades' onClick={scroll}>Habilidades</a></li>
          <li><a href='#portfolio' title='Portfólio' onClick={scroll}>Portfólio</a></li>
          <li><a href='#blog' title='Blog' onClick={scroll}>Blog</a></li>
          <li><a href='#comments' title='Comentários' onClick={scroll}>Comentários</a></li>
          <li><a href='#contact' title='Contato' onClick={scroll}>Contato</a></li>
        </ul>
        <SocialNetwork />
      </div>
    </MenuContainer>
  )
}

export default Menu

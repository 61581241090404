const colors = {
  white: '#FFFFFF',
  black: '#363636',
  red: '#C93D3E',
  yellow: '#FFB429',
  gray: {
    100: '#F9F9F9',
    200: '#EBEBEB',
    500: '#636363',
    700: '#403F3F',
  },
} as const

export default colors

import { MouseEvent } from 'react'

function scrollTo (evt: MouseEvent) {
  evt.preventDefault()
  const anchor = evt.currentTarget as HTMLAnchorElement
  const id = anchor.hash
  const bodyTop = document.body.getBoundingClientRect().top
  const elementTop = (document.querySelector(id) as HTMLElement).getBoundingClientRect().top
  const offsetTop = elementTop - bodyTop
  window.scroll({
    top: offsetTop - 60,
    left: 0,
    behavior: 'smooth',
  })
}

export default scrollTo

import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { MdLightbulbOutline } from 'react-icons/md'

// Components
import Logo from 'src/components/Logo'
import Menu from 'src/components/Menu'

// Helpers
import { trackClick } from 'src/helpers/ga'

// Styles
import { Container } from 'src/styles/grid'
import { HeaderContainer, HeaderLamp } from './style'

type HeaderProps = {
  siteTitle: string;
  theme: string;
  setTheme: (value: string) => void;
}

function Header ({ siteTitle, theme, setTheme }: HeaderProps) {
  const headerRef = useRef(null)
  const [ scrolling, setScrolling ] = useState(false)

  const handleClick = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    window.__setTheme(newTheme)
    setTheme(newTheme)
    trackClick('Lâmpada', 'Clique', theme)
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      setScrolling(scrollY > 10)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <HeaderContainer ref={headerRef} active={scrolling}>
      <Container>
        <Link className='content-left' title={siteTitle} to='/'>
          <Logo />
          <h1>{siteTitle}</h1>
        </Link>
        <div className='content-right'>
          <Menu />
          <HeaderLamp role='button' title={theme === 'dark' ? 'Acender luz' : 'Apagar luz'} onClick={handleClick}>
            <span>Lâmpada</span>
            <MdLightbulbOutline />
          </HeaderLamp>
        </div>
      </Container>
    </HeaderContainer>
  )
}

export default Header

import React from 'react'

function Logo () {
  return (
    <svg className='logo' width='57' height='42' viewBox='0 0 57 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M38.089 41.9428L22.9841 30.4758L8.48865 30.3733L23.6657 41.9428H38.089Z' fill='#363636' />
      <path d='M32.5215 34.6808L32.5446 10.1101L24.4309 10.103L24.3188 28.6231L32.5215 34.6808Z' fill='#363636' />
      <path d='M56.9739 0H6.58999L0.1073 8.11449H50.4729L56.9739 0Z' fill='#363636' />
      <path d='M0 10.1181L14.4065 10.1197L22.2397 16.1201L22.2429 26.9596L0 10.1181Z' fill='#363636' />
      <path d='M57 41.6878L42.5927 41.6862L34.7603 35.6866L34.7571 24.8463L57 41.6878Z' fill='#363636' />
    </svg>
  )
}

export default Logo

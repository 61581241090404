import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'

export const FooterContainer = styled.footer`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};

  p {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    font-size: ${fonts.xs};
    margin: 0 ${spaces.lg};
    position: relative;
    text-align: center;

    &:not(:last-child) {
      &::after {
        content: '';
        right: -${spaces.lg};
        height: 4px;
        width: 4px;
        border-radius: 4px;
        background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

`

import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import spaces from 'src/styles/spaces'

export const SocialNetworkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
    margin: 0 ${spaces.sm};

    &:hover {
      cursor: pointer;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }

`

import styled, { ThemeProps, DefaultTheme } from 'styled-components'
import fonts from 'src/styles/fonts'
import spaces from 'src/styles/spaces'
import breakpoints from 'src/styles/breakpoints'

import { SocialNetworkContainer } from 'src/components/SocialNetwork/style'

export const MenuContainer = styled.nav`

  .burger {
    svg {
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
      width: 25px;
      height: 25px;
    }
  }

  .content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: -5;
    transition: all 0.2s ease-in-out;

    &.active {
      opacity: 1;
      visibility: visible;
      z-index: 5;
    }

    .back {
      position: absolute;
      top: ${spaces.xl};
      left: ${spaces.xl};

      svg {
        color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
        width: 30px;
        height: 30px;
      }
    }

    .list {

      li {
        margin: ${spaces.xxl} 0;

        a {
          color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};
          font-size: ${fonts.xl};
          font-weight: 400;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
          }
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    float: right;

    .burger {
      display: none;
    }

    .content {
      position: relative;
      background-color: transparent;
      opacity: 1;
      visibility: visible;
      z-index: 5;

      .back {
        display: none;
      }

      .list {
        display: flex;
        align-items: center;

        li {
          margin: ${spaces.sm} ${spaces.lg};

          a {
            font-size: ${fonts.sm};
            font-weight: 500;
          }
        }
      }

      ${SocialNetworkContainer} {
        display: none;
      }
    }
  }

`

import { DefaultTheme } from 'styled-components'
import colors from './colors'

export const light: DefaultTheme = {
  general: {
    primary: colors.red,
  },
  lamp: colors.yellow,
  text: {
    primary: colors.black,
    secondary: colors.gray[500],
  },
  background: {
    primary: colors.gray[100],
    secondary: colors.white,
  },
}

export const dark: DefaultTheme = {
  general: {
    primary: colors.yellow,
  },
  lamp: colors.white,
  text: {
    primary: colors.white,
    secondary: colors.white,
  },
  background: {
    primary: colors.black,
    secondary: colors.gray[700],
  },
}

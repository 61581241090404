import React, { MouseEvent } from 'react'
import { AiOutlineGithub, AiFillLinkedin } from 'react-icons/ai'
import { FaHackerrank } from 'react-icons/fa'

// Helpers
import { trackClick } from 'src/helpers/ga'

// Styles
import { SocialNetworkContainer } from './style'

export default function SocialNetwork () {
  function handleClick (evt: MouseEvent) {
    evt.preventDefault()
    const anchor = evt.currentTarget as HTMLAnchorElement
    trackClick('Contato', 'Clique em rede social', anchor.title)
    window.open(anchor.href)
  }

  return (
    <SocialNetworkContainer>
      <a
        onClick={handleClick}
        href='https://github.com/thiagosalome'
        target='_blank'
        rel='noopener noreferrer'
        title='GitHub'
      >
        <AiOutlineGithub />
      </a>
      <a
        onClick={handleClick}
        href='https://www.linkedin.com/in/thiagosalome'
        target='_blank'
        rel='noopener noreferrer'
        title='Linkedin'
      >
        <AiFillLinkedin />
      </a>
      <a
        onClick={handleClick}
        href='https://www.hackerrank.com/thiagosalome'
        target='_blank'
        rel='noopener noreferrer'
        title='HackerRank'
      >
        <FaHackerrank />
      </a>
    </SocialNetworkContainer>
  )
}

import styled, { css, ThemeProps, DefaultTheme } from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

import spaces from 'src/styles/spaces'

type HeaderContainer = {
  active: boolean;
}

export const HeaderContainer = styled.header<HeaderContainer>`
  width: 100%;
  position: absolute;
  height: 70px;
  z-index: 10;
  transition: all 0.3s ease-in-out;

  .logo {
    transition: all 0.3s ease-in-out;
    max-width: 40px;

    path {
      fill: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary}
    }

    @media (min-width: ${breakpoints.md}) {
      max-width: 55px;
    }
  }

  .content-left {
    h1 {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }
  }

  .content-right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @media (min-width: ${breakpoints.md}) {
      flex-direction: row;
    }
  }

  ${(props: HeaderContainer) => {
    if (props.active) {
      return (
        css`
          position: fixed;
          background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
          box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;
          height: 60px;

          .logo {
            max-width: 45px;
          }
        `
      )
    }
  }}

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
`

export const HeaderLamp = styled.button`
  margin-right: ${spaces.sm};
  cursor: pointer;
  outline: none;

  &:hover {
    transform: scale(1.1)
  }

  span {
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-right: 0;
    margin-left: ${spaces.sm};
  }

  svg {
    width: 25px;
    height: 25px;

    path {
      fill: ${(props: ThemeProps<DefaultTheme>) => props.theme.lamp};
    }
  }
`

const fonts = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.6rem',
  lg: '2rem',
  xl: '2.5rem',
  xxl: '4rem',
} as const

export default fonts

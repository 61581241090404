import { createGlobalStyle, ThemeProps, DefaultTheme } from 'styled-components'
import fonts from './fonts'
import spaces from './spaces'
import 'react-multi-carousel/lib/styles.css'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Montserrat', sans-serif;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  * {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  a {
    text-decoration: none;
  }

  button {
    background: none;
    border: 0;
    padding: 0;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  html {
    font-size: 10px;
  }

  main {
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.primary};
  }

  .not-found {
    background-color: ${(props: ThemeProps<DefaultTheme>) => props.theme.background.secondary};
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      max-width: 150px;
      height: auto;
      fill: ${(props: ThemeProps<DefaultTheme>) => props.theme.general.primary};
      margin-bottom: ${spaces.xl};
    }

    h2 {
      font-size: ${fonts.xl};
      font-weight: 200;
      text-align: center;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.primary};

      strong {
        font-weight: bold;
      }
    }

    p {
      font-size: ${fonts.sm};
      margin: ${spaces.xl} 0;
      text-align: center;
      line-height: 1.9;
      color: ${(props: ThemeProps<DefaultTheme>) => props.theme.text.secondary};
    }
  }
`

export default GlobalStyle

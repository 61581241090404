import styled, { css } from 'styled-components'
import breakpoints from './breakpoints'

type Col = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

type Row = {
  centered?: boolean;
  spaceBetween?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${breakpoints.sm}) {
    max-width: 540px;
  }
  @media (min-width: ${breakpoints.md}) {
    max-width: 720px;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${breakpoints.xl}) {
    max-width: 1140px;
  }
`

export const Row = styled.div<Row>`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  ${(props: Row) => {
    if (props.centered) {
      return (
        css`
          justify-content: center;
        `
      )
    } else if (props.spaceBetween) {
      return (
        css`
          justify-content: space-between;
        `
      )
    }
  }}
`

export const Col = styled.div<Col>`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  /* Col */
  /* flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; */

  /* Col Auto */
  /* flex: 0 0 auto;
  width: auto;
  max-width: none; */

  ${(props: Col) => {
    let medias = ''
    if (props.xs) {
      medias += `
        @media (max-width: ${breakpoints.sm}) {
          flex: 0 0 ${(props.xs / 12) * 100}%;
          max-width: ${(props.xs / 12) * 100}%;
        }
      `
    }
    if (props.sm) {
      medias += `
        @media (min-width: ${breakpoints.sm}) {
          flex: 0 0 ${(props.sm / 12) * 100}%;
          max-width: ${(props.sm / 12) * 100}%;
        }
      `
    }
    if (props.md) {
      medias += `
        @media (min-width: ${breakpoints.md}) {
          flex: 0 0 ${(props.md / 12) * 100}%;
          max-width: ${(props.md / 12) * 100}%;
        }
      `
    }
    if (props.lg) {
      medias += `
        @media (min-width: ${breakpoints.lg}) {
          flex: 0 0 ${(props.lg / 12) * 100}%;
          max-width: ${(props.lg / 12) * 100}%;
        }
      `
    }
    if (props.xl) {
      medias += `
        @media (min-width: ${breakpoints.xl}) {
          flex: 0 0 ${(props.xl / 12) * 100}%;
          max-width: ${(props.xl / 12) * 100}%;
        }
      `
    }
    return css`${medias}`
  }}
`

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'

// Components
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'

// Styles
import './layout.css'
import { light, dark } from 'src/styles/themes'
import GlobalStyle from 'src/styles/global'

type LayoutProps = {
  children: ReactNode;
}

function Layout ({ children }: LayoutProps) {
  const [ theme, setTheme ] = useState<string>('light')
  const themes = { light, dark }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    setTheme(window.__theme)
  }, [])

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      <Header
        theme={theme}
        setTheme={setTheme}
        siteTitle={data.site.siteMetadata.title}
      />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout

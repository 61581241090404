import React from 'react'

// Styles
import { FooterContainer } from './style'

const Footer = () => (
  <FooterContainer className='test-footer'>
    <p>© {new Date().getFullYear()} Thiago Gonçalves Salomé - Todos os direitos reservados</p>
    {/* <Link to='/policies/'>Política de Privacidade</Link> */}
    {/* <Link to=''>Termos de Uso</Link> */}
  </FooterContainer>
)

export default Footer
